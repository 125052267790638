// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SlackSettings-module__Pmz1QGYiP5Q5ZNfN31hw {
  margin-bottom: 20px;
}

.SlackSettings-module__vj065UBuwqhtMH5opzWA {
  width: 100%;
  padding: 50px 0;
}

.SlackSettings-module__rKLW8bpJFPsLTNfdpmZC .SlackSettings-module__lKipPoLSmX31bB6VpEAT {
  width: 400px;
}

.SlackSettings-module__cCt0VoZWRwF74OKovfsm {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(204, 204, 220, 0.25);
}

.SlackSettings-module__f_uOt74uF0CnL3RfkeHa {
  text-align: center;
  width: 725px;
}

.SlackSettings-module__J1hVZ9flt3Br99gqEmn2 {
  margin-right: 4px;
  align-self: baseline;
}

.SlackSettings-module__thRoviIwGnhBbYczeGKi {
  height: 30px;
}

.SlackSettings-module__kFQZTlApZnWItzIqehyQ {
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 24px;
}

.SlackSettings-module__W2uFQT5ThFO_lfh8nzk5 {
  margin-top: 24px;
}

.SlackSettings-module__JxEDqKJhJCtodCMGqm_i {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.SlackSettings-module__AOoL99hHSi9lq6VZWeiH svg {
  display: none;
}

.SlackSettings-module__AaJTB_OfSA1yvAyyRTJA {
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./pages/settings/tabs/ChatOps/tabs/SlackSettings/SlackSettings.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".title {\n  margin-bottom: 20px;\n}\n\n.stub {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.root .select {\n  width: 400px;\n}\n\n.slack-settings {\n  margin-bottom: 20px;\n  border-bottom: 1px solid rgba(204, 204, 220, 0.25);\n}\n\n.slack-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.external-link-style {\n  margin-right: 4px;\n  align-self: baseline;\n}\n\n.team_workspace {\n  height: 30px;\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.infoblock-icon {\n  margin-top: 24px;\n}\n\n.upgradeSlackBtn {\n  position: absolute;\n  right: 20px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.upgradeSlackAlert svg {\n  display: none;\n}\n\n.linkToIncidentWrapper {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SlackSettings-module__Pmz1QGYiP5Q5ZNfN31hw`,
	"stub": `SlackSettings-module__vj065UBuwqhtMH5opzWA`,
	"root": `SlackSettings-module__rKLW8bpJFPsLTNfdpmZC`,
	"select": `SlackSettings-module__lKipPoLSmX31bB6VpEAT`,
	"slack-settings": `SlackSettings-module__cCt0VoZWRwF74OKovfsm`,
	"slack-infoblock": `SlackSettings-module__f_uOt74uF0CnL3RfkeHa`,
	"external-link-style": `SlackSettings-module__J1hVZ9flt3Br99gqEmn2`,
	"team_workspace": `SlackSettings-module__thRoviIwGnhBbYczeGKi`,
	"infoblock-text": `SlackSettings-module__kFQZTlApZnWItzIqehyQ`,
	"infoblock-icon": `SlackSettings-module__W2uFQT5ThFO_lfh8nzk5`,
	"upgradeSlackBtn": `SlackSettings-module__JxEDqKJhJCtodCMGqm_i`,
	"upgradeSlackAlert": `SlackSettings-module__AOoL99hHSi9lq6VZWeiH`,
	"linkToIncidentWrapper": `SlackSettings-module__AaJTB_OfSA1yvAyyRTJA`
};
export default ___CSS_LOADER_EXPORT___;
